import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDVjA2zrfLFeLi1_vYsbeEB3b2-nZXkbOE",
  authDomain: "anyexpert-landing.firebaseapp.com",
  projectId: "anyexpert-landing",
  storageBucket: "anyexpert-landing.appspot.com",
  messagingSenderId: "886881867435",
  appId: "1:886881867435:web:acac836447101f342709ee",
  measurementId: "G-PM2VW9K8FQ"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export { db };