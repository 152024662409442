import React from "react";
import Navbar from "../../Shared/Navbar/Navbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Time = () => {
  return (
    <div className=" min-h-[90vh] sm:min-h-[90vh]  ">
      <ToastContainer />
      <div className="pt-[80px] mt-[0px] fontClass text-center text-[53px] md:text-[119.75px] leading-[62px] md:leading-[140.34px] text-[#313131] font-black ">

        Any <span className="font-extralight  ml-[-10px] md:ml-[-30px] ">Time</span>
      </div>
      <div className=" mt-[100px]     flex  flex-col-reverse md:flex-row    justify-center  gap-[50px] lg:gap-[150px]  items-center">
        <div>
          <img src={require("../../Shared/Images/Frame 165.png")} alt="" />
        </div>
        <div>
          <div className="font-semibold fontClass  text-center md:text-start text-[24px]   md:text-[36px] leading-[28px] md:leading-[42.19px] text-[#313131]">

            Step 2
          </div>
          <div className="max-w-[230px] mt-[10px] mx-auto fontClass text-[15px] leading-[17.58px] block md:hidden text-center">
            Schedule a 1:1 video call with your expert at the next available
            slot.
            <br />
            <br />
            Don’t want to wait? Start chatting with your experts AI
            immediately.
          </div>
          <div className="mt-[20px]  max-w-[398px] text-start text-[22.51px] leading-[26.37px]  hidden md:block ">
            Schedule a 1:1 video call with your expert at the next available
            slot.
            <br />
            <br />
            Don’t want to wait? Start chatting with your experts AI
            immediately.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Time;
