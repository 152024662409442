import React from "react";
import Navbar from "../../Shared/Navbar/Navbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Where = () => {
  return (
    <div className=" min-h-[90vh] sm:min-h-[90vh]">
      <ToastContainer />

      <div className="pt-[80px] fontClass text-center text-[53px] md:text-[119.75px] leading-[62px] md:leading-[140.34px] text-[#313131] font-black ">

        Any <span className="font-extralight  ml-[-10px] md:ml-[-30px] ">Where</span>
      </div>
      <div className=" mt-[50px]  flex  flex-col-reverse md:flex-row justify-center gap-[150px]  items-center">
        <div className="relative hidden md:block">
          <img src={require("../../Shared/Images/Group 20.png")} alt="" />
          <img src={require("../../Shared/Images/Frame (1).png")} alt="" className="absolute top-[20%] left-[60%]" />

        </div>
        <div className="relative  block md:hidden ">
          <img src={require("../../Shared/Images/Group 19.png")} alt="" />
          <img src={require("../../Shared/Images/Frame (2).png")} alt="" className="absolute top-[-30%] left-[42%]" />

        </div>
        <div>
          <div className="font-semibold fontClass  text-center md:text-start text-[24px]   md:text-[36px] leading-[28px] md:leading-[42.19px] text-[#313131]">
            Step 3
          </div>
          <div className="max-w-[230px] mt-[10px] mx-auto fontClass text-[15px] leading-[17.58px] block md:hidden text-center ">
            Speak to an expert from anywhere in the world and get their
            guidance from wherever you are!
          </div>
          <div className="mt-[20px]  max-w-[398px] text-start text-[22.51px] leading-[26.37px] hidden md:block ">
            Speak to an expert from anywhere in the world and get their
            guidance from wherever you are.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Where;
