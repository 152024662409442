import React, { useState } from "react";
import Navbar from "../Shared/Navbar/Navbar";
import { doc, addDoc, setDoc, collection } from "firebase/firestore";
import { db } from '../../config/firebase.js';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Timestamp } from "@firebase/firestore";

const AnyExpert = () => {
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };

  const [email, setEmail] = useState("");

  const handleSubmit = async () => {
    if (email) {

      await setDoc(doc(db, "waitlist", email), {
        email: email,
        date: Timestamp.fromDate(new Date()),
      });

      await addDoc(collection(db, "mail", ""), {
        to: [email],
        template: {
          name: 'waitlist_v1',
        },
      });

      showToastMessage();

      document.getElementById("contact-form-expert-waitlist").reset();
    }
  }

  const showToastMessage = () => {
    toast("Thank you for your interest!", {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
      theme: 'colored',
      style: { background: '#FFFCF9' },
    });
  };

  return (
    <div className="  min-h-[70vh] sm:min-h-[90vh]">
      <ToastContainer />
      <div className=" mt-[100px] fontClass text-center text-[56px] md:text-[183.75px]  leading-[65px] md:leading-[214.34px] text-[#F0ECE8] font-black ">
        Any{" "}
        <span className="font-extralight ml-[-10px] md:ml-[-50px]">Expert</span>
      </div>

      <div className=" mt-[50px]  text-start flex justify-center items-center  pr-[30px]  md:pr-[100px] fontClass text-[16px] md:text-[22.51px]  leading-[18.75px] md:leading-[26.37px]   text-[#F0ECE8]">
        Join our waitlist, we’ll be in touch!
      </div>
      <div className="flex flex-col md:flex-row gap-[10px] justify-center items-center  pr-[20px] md:pr-[0px] ">
        <div className="mt-[10px] w-[253px] md:w-[275px]">
          <form id="contact-form-expert-waitlist">
            <input
              type="text"
              name=""
              placeholder="Your e-mail"
              id=""
              className="h-[39px]  bg-[#474747] w-[253px] md:w-[275px] rounded-[8px] text-[16px] leading-[18.75px] pl-[10px] font-light w-full bg-[#474747] text-[#F0ECE8] border-[#F0ECE8] border-[1px] "
              onChange={(e) => setEmail(e.target.value)}
            />
          </form>
        </div>
        <div className="ml-[6px] md:mt-[15px] rounded-[8px] border-[#F0ECE8] h-[40px] border-[1px] sm:pr-[5px] pb-[5px] w-[253px] md:w-[147px]" onClick={handleSubmit}>
          <div className=" rounded-[8px] flex justify-center items-center ml-[-5px] mt-[-5px] h-[39px] text-center  w-[253px] md:w-[147px] w-full bg-[#F0ECE8] font-black fontClass text-[18.91px] text-[#474747] leading-[22.16px]">
            Join Waitlist
          </div>
        </div>
      </div>
      <div className="  mt-[50px] text-[#F0ECE8] text-center latClass font-medium text-[17.05px] leading-[20.46px] pr-[20px] md:pr-[0px] ">
        <span>Questions?</span>{" "}
        <span
          className="border-b-[1px] border-b-[white] cursor-pointer"
          onClick={scrollToBottom}
        >
          Contact us
        </span>
      </div>
    </div>
  );
};

export default AnyExpert;
