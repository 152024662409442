import React, { useState } from "react";
import Navbar from "../../Shared/Navbar/Navbar";
import { doc, addDoc, setDoc, collection } from "firebase/firestore";
import { db } from '../../../config/firebase.js';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Timestamp } from "@firebase/firestore";

const Waitlist = () => {
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };

  const [email, setEmail] = useState("");

  const handleSubmit = async () => {
    if (email) {

      await setDoc(doc(db, "waitlist", email), {
        email: email,
        date: Timestamp.fromDate(new Date()),
      });

      await addDoc(collection(db, "mail", ""), {
        to: [email],
        template: {
          name: 'waitlist_v1',
        },
      });

      showToastMessage();

      document.getElementById("contact-form-customer-waitlist").reset();
    }
  }

  const showToastMessage = () => {
    toast("Thank you for your interest!", {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
      theme: 'colored',
      style: { background: '#FFFCF9' },
    });
  };

  return (
    <div className="min-h-[70vh]">
      <ToastContainer />
      <div className=" pt-[80px] fontClass text-center text-[56px] md:text-[183.75px]  leading-[65px] md:leading-[214.34px] text-[#313131] font-black ">
        Any{" "}
        <span className="font-extralight ml-[-10px] md:ml-[-50px]">Expert</span>
      </div>

      <div className=" mt-[50px]  text-start flex justify-center items-center  pr-[80px]  md:pr-[160px] fontClass text-[16px] md:text-[22.51px]  leading-[18.75px] md:leading-[26.37px]   text-[#313131]">
        Join our waitlist and get
        <br />
        first access to our experts.
      </div>
      <div className="flex flex-col   md:flex-row gap-[10px] justify-center items-center  pr-[20px] md:pr-[0px] ">
        <div className="mt-[10px] w-[253px] md:w-[275px]">
          <form id="contact-form-customer-waitlist">
            <input
              type="text"
              name=""
              placeholder="Your e-mail"
              id=""
              className="h-[39px]  w-[253px] md:w-[275px] rounded-[8px] text-[16px] leading-[18.75px] pl-[10px] font-light w-full text-[black] border-[#313131] border-[1px] "
              onChange={(e) => setEmail(e.target.value)}
            />
          </form>
        </div>
        <div className="ml-[6px] md:mt-[15px] rounded-[8px] border-[#313131] h-[40px] border-[1px] sm:pr-[5px] pb-[5px] w-[253px] md:w-[147px]" onClick={handleSubmit}>
          <div className=" rounded-[8px] flex justify-center items-center ml-[-5px] mt-[-5px] h-[39px] text-center  w-[253px] md:w-[147px] w-full bg-[#313131] font-black fontClass text-[18.91px] text-[#F0ECE8] leading-[22.16px]">
            Join Waitlist
          </div>
        </div>
      </div>

      <div className=" mt-[50px] text-center latClass font-medium text-[17.05px] leading-[20.46px] pr-[20px] md:pr-[0px] ">
        Questions?{" "}
        <span className="border-b-[1px] border-b-[black] cursor-pointer" onClick={scrollToBottom}>Contact Us</span>
      </div>
    </div>
  );
};

export default Waitlist;