import logo from "./logo.svg";
import "./App.css";
import { Routes, Route, Switch } from "react-router-dom";
import Home from "./Component/Customer/Home/Home";
import Navbar from "./Component/Shared/Navbar/Navbar";
import Topic from "./Component/Customer/Topic/Topic";
import Time from "./Component/Customer/Time/Time";
import Where from "./Component/Customer/Where/Where";
import Waitlist from "./Component/Customer/Waitlist/Waitlist";
import Contact from "./Component/Customer/Contact/Contact";
import ExpertPage from "./Component/Expert/ExpertPage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/topic" element={<Topic />} />
      <Route path="/time" element={<Time />} />
      <Route path="/any-where" element={<Where />} />
      <Route path="/any-expert" element={<Waitlist />} />
      <Route path="/expert" element={<ExpertPage />} />
      <Route path="/contact-us" element={<Contact />} />
    </Routes>
  );
}

export default App;
