import React, { useEffect, useState, useRef } from "react";
import Topic from "../Topic/Topic";
import Time from "../Time/Time";
import Where from "../Where/Where";
import Expert from "../Waitlist/Waitlist";
import Contact from "../Contact/Contact";
import Navbar from "../../Shared/Navbar/Navbar";
import { doc, addDoc, setDoc, collection } from "firebase/firestore";
import { db } from '../../../config/firebase.js';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Timestamp } from "@firebase/firestore";

const Home = () => {
  const topicRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [index, setIndex] = useState(0);

  const [currentText, setCurrentText] = useState("Any");
  const [animationClass, setAnimationClass] = useState("");

  const [email, setEmail] = useState("");

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  const scrollToTopic = () => {
    if (topicRef.current) {
      topicRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleSubmit = async () => {
    if (email) {

      await setDoc(doc(db, "waitlist", email), {
        email: email,
        date: Timestamp.fromDate(new Date()),
      });

      await addDoc(collection(db, "mail", ""), {
        to: [email],
        template: {
          name: 'waitlist_v1',
        },
      });

      showToastMessage();

      document.getElementById("contact-form-customer-home").reset();
    }
  }

  const showToastMessage = () => {
    toast("Thank you for your interest!", {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
      theme: 'colored',
      style: { background: '#FFFCF9' },
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const words = ["Any", "growth", "HR", "education", "finance", "Any"];
    let index = 0;

    const changeWord = () => {
      if (index < words.length) {
        setCurrentText(words[index]);

        setTimeout(() => {
          setAnimationClass("");
          index++;
          setIndex(index);

          if (index < words.length) {
            changeWord();
          }
        }, 2000); // Change every 2 seconds
      }
    };
    // changeWord(); // Initial call to start the animation
  }, []);
  return (
    <div className="bg-[#F0ECE8]">
      <div className={`navbar-container     sad`}>
        <Navbar />
      </div>
      <ToastContainer />
      <div className=" min-h-[100vh] flex flex-col justify-center items-center ">
        <div className="gradient-text1 hidden md:block text-center font-black fontClass text-[40px] leading-[60px] pr-[420px]">
          <div className="scroll-color-section pl-[70px]">growth</div>
          <div className="scroll-color-section mt-[-15px] pl-[120px]">HR</div>
          <div className="scroll-color-section mt-[-15px]">education</div>
          <div className="scroll-color-section mt-[-15px] pl-[50px]">
            finance
          </div>
        </div>

        <div className="gradient-text1 block md:hidden text-end max-w-[200px] mx-auto font-black fontClass text-[44px] leading-[60px] mt-[-50px]">
          <div>finance</div>
          <div className="mt-[-15px]">product</div>
          <div className="mt-[-15px]">design</div>
          <div className="mt-[-15px]">marketing</div>
        </div>

        <div
          className={`fontClass hidden md:block text-center text-[53px] pr-[100px] md:text-[119.75px] leading-[62px] md:leading-[140.34px] text-[#313131] font-black `}
        >
          <span>Any </span>
          <span className="font-extralight md:ml-[-30px]">Expert</span>
        </div>

        <div className="block md:hidden fontClass text-start mt-[-10px] pr-[56px] md:pr-[0px] max-w-[350px] text-[53px] md:text-[119.75px] leading-[52px] md:leading-[140.34px] text-[#313131] font-black">
          Any <br />
          <span className="font-extralight md:ml-[-30px]">Expert</span>
        </div>

        <div className="flex mt-[20px] justify-center items-start pr-[30px]  gap-[150px]  ">
          <div className="gradient-text hidden md:block text-right font-black fontClass text-[40px] leading-[60px]">
            <div
              className={`pl-[70px] ${index < 5 && index !== 0 ? "block" : "hidden"
                }`}
            >
              Any
            </div>
            <div className="mt-[-15px]">design</div>
            <div className="mt-[-15px]">SEO</div>
            <div className="mt-[-15px]">brand</div>{" "}
            <div className="mt-[-15px]">strategy</div>
          </div>

          <div>
            <div className="ml-[45px] sm:ml-[0px] max-w-[207px] fontClass text-[16px] leading-[18.75px] text-[#313131]">
              One-on-one guidance on any topic, at any time.
              <br /> <br />
              Join our waitlist and get first access to our experts.
            </div>
            <form id="contact-form-customer-home">
              <div className="ml-[45px] sm:ml-[0px] mt-[10px]">
                <input
                  type="text"
                  name=""
                  placeholder="Your e-mail"
                  id=""
                  className="h-[39px] max-w-[231px] bg-[#FFFCF9] rounded-[8px] text-[16px] leading-[18.75px] pl-[10px] font-light w-full text-[black] border-[#313131] bg-[#FFFCF9] border-[1px]"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="md:ml-[5px] ml-[50px] sm:ml-[0px] mt-[10px] rounded-[8px] border-[#313131] h-[39px] border-[1px] pb-[5px]" onClick={handleSubmit}>
                <div className="rounded-[8px] flex justify-center items-center ml-[-5px] mt-[-5px] h-[39px] text-center max-w-[231px] w-full bg-[#313131] font-black fontClass text-[18.91px] text-[#F0ECE8] leading-[22.16px]">
                  Join Waitlist
                </div>
              </div>
            </form>
          </div>
        </div>

        <div
          className="mt-[50px] md:mt-[100px] flex px-[20px] justify-end md:justify-center items-center"
          style={{ cursor: "pointer" }}
          onClick={scrollToTopic}
        >
          <img
            src={require("../../Shared/Images/Vector 1.png")}
            alt=""
            className="animated-image"
          />
        </div>
      </div>

      <div ref={topicRef}>
        <Topic />
      </div>
      <Time />
      <Where />
      <Expert />
      <Contact />
    </div>
  );
};

export default Home;
