import React, { useState } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const pathname = window.location.pathname;
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };
  const [show, setShow] = useState(false);
  return (
    <div className="flex justify-between items-center py-[20px] px-[20px] md:px-[50px]">
      <div>
        {pathname === "/expert" ? (
          <img
            src={require("../Images/Logo (1).png")}
            alt=""
            className="h-[40px] w-[40px]"
          />
        ) : (
          <img
            src={require("../Images/Logo.png")}
            alt=""
            className="h-[40px] w-[40px]"
          />
        )}
      </div>
      <div
        className={`relative z-10 max-w-[165px] md:max-w-[260px] rounded-[8px]  ${
          pathname === "/expert" ? "bg-[#474747]" : "bg-[#FFFCF9]"
        }    p-[5px] flex flex-col items-center`}
      >
        <div className="relative w-full flex justify-center items-center max-w-[165px] md:max-w-[260px]">
          <div
            className={`absolute transition-transform duration-1000 ease-in-out ${
              pathname === "/expert"
                ? "right-[0px] translate-x-0 animate__animated animate__backInLeft "
                : "left-[0px] translate-x-0 animate__animated animate__backInRight "
            } bg-[#313131] w-[80px] md:w-[119px] h-[30px] md:h-[39px] rounded-[8px]`}
          ></div>
          <Link to={"/"}>
            <div
              className={`fontClass ${
                pathname === "/" ? " text-[#F0ECE8]" : "text-[#F0ECE8]"
              } rounded-[8px] px-[15px] relative z-10 md:pl-[20px] md:pr-[30px] py-[5px] md:py-[10px] text-[12px] md:text-[16px] font-bold leading-[18.75px] text-start`}
            >
              Customers
            </div>
          </Link>
          <Link to={"/expert"}>
            <div
              className={`fontClass ${
                pathname === "/expert" ? " text-[#F0ECE8]" : "text-[#313131]"
              } rounded-[8px] px-[15px] relative z-10 md:pl-[20px] md:pr-[30px] py-[5px] md:py-[10px] text-[12px] md:text-[16px] font-bold leading-[18.75px] text-center`}
            >
              Experts
            </div>
          </Link>
        </div>
      </div>

      <div className="flex gap-[10px]">
        <div>
          {pathname === "/expert" ? (
            <img
              src={require("../Images/Frame (3).png")}
              alt=""
              onClick={scrollToBottom}
              className="cursor-pointer"
            />
          ) : (
            <img
              src={require("../Images/Frame.png")}
              alt=""
              onClick={scrollToBottom}
              className="cursor-pointer"
            />
          )}
        </div>
        <div
          className={`  ${
            pathname === "/expert"
              ? "text-[#E7E3DC]  border-b-[#E7E3DC] "
              : "border-b-[#313131] text-[#313131]"
          }    border-b-[1px]     hidden md:block  text-[17.05px]  font-medium latClass cursor-pointer leading-[20.46px] `}
          onClick={scrollToBottom}
        >
          Contact Us
        </div>
      </div>
    </div>
  );
};

export default Navbar;
