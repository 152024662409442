import React, { useState } from "react";
import Navbar from "../Shared/Navbar/Navbar";
import { doc, addDoc, collection } from "firebase/firestore";
import { db } from '../../config/firebase.js';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ExpertContact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async () => {
    if (name && email && message) {
      await addDoc(collection(db, "mail", ""), {
        to: ["prudhvi@any.expert"],
        message: {
          'subject': 'Contact Us',
          'text': `Name: ${name} \n Email: ${email} \n Message: ${message}`
        },
      });

      showToastMessage();

      document.getElementById("contact-form-expert").reset();
    }
  }

  const showToastMessage = () => {
    toast("Thank you, we'll get back to you as soon as possible!", {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
      theme: 'colored',
      style: { background: '#F0ECE8' },
    });
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="min-h-[80vh]" >
      <ToastContainer />
      <form id="contact-form-expert">
        <div className=" fontClass text-center  text-[53px]   md:text-[119.75px]   leading-[62px] md:leading-[140.34px] text-[#CBA135] font-black ">
          Contact <span className="font-extralight ml-[-10px] md:ml-[-30px] text-[#F0ECE8]">Us</span>
        </div>
        <div className="  mt-[20px]  max-w-[292px] md:max-w-[398px] mx-auto">
          <div className="fontClass  leading-[18.75px] text-[16px] md:text-[22.51px] md:leading-[26.37px] text-[#F0ECE8]">
            Name
          </div>
          <div className="mt-[10px]">
            <input
              type="text"
              name=""
              id=""
              placeholder="name"
              className="p-[10px] bg-[#474747] w-full bg-[#474747] rounded-[8px] border-[1px] border-[#F0ECE8] text-[#F0ECE8]"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>
        <div className="  mt-[20px] max-w-[292px] md:max-w-[398px] mx-auto">
          <div className="fontClass leading-[18.75px] text-[16px] md:text-[22.51px] md:leading-[26.37px] text-[#F0ECE8]">
            Email
          </div>
          <div className="mt-[10px]">
            <input
              type="text"
              name=""
              id=""
              placeholder="example@mail.com"
              className="bg-[#474747] p-[10px] w-full bg-[#474747] rounded-[8px] border-[1px] border-[#F0ECE8] text-[#F0ECE8]"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="  mt-[20px] max-w-[292px] md:max-w-[398px] mx-auto">
          <div className="fontClass leading-[18.75px] text-[16px] md:text-[22.51px] md:leading-[26.37px] text-[#F0ECE8]">
            Message
          </div>
          <div className="mt-[10px]">
            <textarea
              type="text"
              name=""
              rows={3}
              id=""
              placeholder="type your message here..."
              className="bg-[#474747] p-[10px] mb-[10px] w-full bg-[#474747] rounded-[8px] border-[1px] border-[#F0ECE8] text-[#F0ECE8]"
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
        </div>
        <div className="ml-[5px]" onClick={handleSubmit}>
          <div style={{ margin: "auto", }} className="rounded-[8px]  flex justify-center bg-[#CBA135] items-center  h-[40px] mx-auto  text-center max-w-[292px] md:max-w-[398px] mx-auto w-[292px] md:w-[398px] ">
            <div className="rounded-[8px] flex border-[1px] text-[18.91px] leading-[22.16px] fontClass font-black  border-[#313131] justify-center items-center mt-[-8px] ml-[-5px] h-[39px] text-center max-w-[292px] md:max-w-[398px] mx-auto w-[292px] md:w-[398px] bg-[#FFFCF9]">
              Send
            </div>
          </div>
        </div>
      </form>
      <div className="relative md:hidden">
        <div className="  mx-auto  mt-[30px] flex justify-center items-center   absolute bottom-[30px] top-[10%]  left-[88%]  md:left-[49%] ">
          <img src={require("../Shared/Images/Vector 1 (3).png")} alt="" className="cursor-pointer animated-image"
            onClick={scrollToTop} />
        </div>
      </div>
    </div>
  );
};

export default ExpertContact;
